<template>
    <div class="container">
        <div class="col-12">
            <div class="row edit-row">
                <div class="editing-title">
                    <p>{{ $t('includedList.addIncludedList') }}</p>
                </div>
            </div>
            <div class="row edit-row">
                <div class="editing-wrapper">
                    <div class="col-12 search-input-with-button">
                        <div class="row">
                            <div class="col-10 search-input-wrapper">
                                <input type="text" :placeholder="$t('placeholder.searchByOptionName')" />
                            </div>
                            <div class="col-2 search-button-wrapper">
                                <button type="button">
                                    <i class="icon-f icon-search fs-24" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 options-wrapper">
                        <div class="option-wrapper" v-for="index in 8" :key="index">
                            <input
                                class="inp-cbx"
                                :name="index + 'cbx'"
                                value="15"
                                :id="index + 'cbx'"
                                type="checkbox"
                                style="display: none"
                            />
                            <label class="cbx w-100" :for="index + 'cbx'" tabindex="12">
                                <span class="col-2">
                                    <svg width="12px" height="10px" viewBox="0 0 12 10">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span class="checkboxtext col-10">Option {{ index }}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row edit-row">
                <div class="col-12">
                    <button class="save-button-wrapper popup-bookit-button w-100" type="button">{{ $t('button.save') }}</button>
                </div>
                <div class="col-6">
                    <button class="close-button-wrapper popup-bookit-button w-100" type="button">{{ $t('button.close') }}</button>
                </div>
                <div class="col-6">
                    <button class="clear-all-button-wrapper popup-bookit-button w-100" type="button">{{ $t('button.clearAll') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    }
};
</script>
<style scoped>
.editing-wrapper {
    height: 57vh;
    overflow-y: auto;
}
</style>
